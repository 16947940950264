import * as React from 'react';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  TextBlock,
  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import useSanityData from '../../../hooks/useSanityData';

import getCardImage from '../../../services/Sanity/query/getImage/getCardImage';

import Skeleton from '../Skeleton';
import IconHandler from '../IconHandler';

export default function Card(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const image = useSanityData(
    getCardImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
      props.data.original_id,
    ),
  );

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const isClickable = props.data.link;
  const isHighlighted = isClickable || props.highlight;
  const src = image[props.params.layoutIndex]?.layout?.Components?.cards?.array?.src;

  const [isLoading, setLoading] = React.useState(true);
  const [layout, setLayout] = React.useState('vertical');

  React.useEffect(() => {
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setLayout(props.layout);
  }, [size]);

  return (
    <>
      {
        !props.data.image
        && props.data.icon
        && (
          <div
            style={{
              flex: '0 1 100px',
              minHeight: 100,
              paddingLeft: isClickable ? 15 : 0,
              paddingRight: isClickable ? 15 : 0,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: (
                props.data.text_block.title === undefined
                || props.data.text_block.align === 'center'
              ) ? 'center'
                : props.data.text_block.align === 'left'
                  ? 'flex-start'
                  : 'flex-end',
              marginTop: size.width < Breakpoint.sm ? 0 : 20,
            }}
          >
            <IconHandler
              data={props.data.icon}
              height={100}
              width={100}
              color={Colors.GREY}
            />
          </div>
        )
      }
      {
        !props.data.icon
        && props.data.image
        && (
          <div
            className={(props.data.image === undefined || props.data.image === null) ? 'Skeleton' : undefined}
            style={{
              flex: layout === 'horizontal' ? '0 1 50%' : '0 1 300px',
              backgroundImage: src !== undefined && `url('${src}?q=50')`,
              backgroundSize: props.data.image_size ?? 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: 300,
            }}
          />
        )
      }

      <div
        style={{
          paddingLeft: isHighlighted
            ? layout === 'horizontal' ? 30 : 15
            : 0,
          paddingRight: isHighlighted ? 15 : 0,
          paddingBottom: isHighlighted ? 7.5 : 0,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: layout === 'horizontal' ? '50%' : '100%',
        }}
      >
        {
          (
            props.data.text_block.title !== undefined
            || props.data.text_block.text !== undefined
          ) ? (
            <div
              style={{
                flex: '1 1 auto',
                marginTop: props.data.text_block.title === undefined ? -15 : 0,
              }}
            >
              <TextBlock
                title={{
                  content: props.data.text_block.title !== undefined && (
                    props.data.text_block.title[lang]
                  ),
                  portableText: props.data.text_block.title !== undefined,
                  fontSize: '2em',
                  heading: 3,
                }}
                text={{
                  content: (!isLoading && props.data.text_block.text !== undefined) && (
                    props.data.text_block.text[lang]
                  ),
                  portableText: !isLoading && props.data.text_block.text !== undefined,
                  color: isClickable ? Colors.DARK_GREY : Colors.GREY,
                }}
                align={
                  size.width < Breakpoint.sm
                    ? (props.data.text_block.align ?? 'left')
                    : (
                      props.data.icon !== undefined
                      && props.data.text_block.title === undefined
                    )
                      ? 'center'
                      : 'left'
                }
              />
            </div>
            ) : (
              <div
                style={{
                  flex: '1 1 auto',
                  paddingTop: 20,
                }}
              >
                <Skeleton
                  count={1}
                  height={40}
                />
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Skeleton
                    count={3}
                  />
                </div>
              </div>
            )
        }
        {
          isClickable && (
            <div
              style={{
                flex: '0 1 50px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                paddingTop: props.data.text_block.title === undefined
                  && props.data.text_block.text === undefined
                  ? 7.5
                  : 'inherit',
              }}
            >
              {
                props.data.link.text !== undefined
                  ? (
                    <Text
                      color={Colors.BLUE}
                    >
                      {props.data.link.text === 'custom' ? props.data.link.custom_text[lang] : _(props.data.link.text, { ns: 'ui' })}
                    </Text>
                  ) : (
                    <Skeleton
                      count={1}
                    />
                  )
              }
              <div
                style={{
                  marginLeft: 10,
                  paddingTop: 3.5,
                  transition: 'margin-left .05s linear',
                }}
              >
                <Icon
                  name="ArrowRight"
                  height={12.5}
                  width={12.5}
                  color={Colors.BLUE}
                  iconSet="FA"
                />
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}
