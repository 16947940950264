import {
  Breakpoint,
} from '@bluecurrent/web-component-lib';

export default function handleResponsive(width) {
  if (width < Breakpoint.sm) {
    return {
      width: '100%',
      layout: 1,
    };
  }
  if (
    (width >= Breakpoint.sm && width < Breakpoint.lg)
  ) {
    return {
      width: 'calc(50% - 8px)',
      layout: 2,
    };
  }

  return {
    width: `calc(${parseFloat(100 / 3)}% - 15px)`,
    layout: 3,
  };
}
