const getCardImage = (
  id,
  layoutIndex,
  objectIndex,
  cardIndex,
) => `
  *[_id == "${id}"] {
    layout[${layoutIndex}] {
      Components[${objectIndex}] {
        cards {
          array[${cardIndex}] {
            'src': image.asset->url
          }
        }
      }
    }
  }
`;

export default getCardImage;
