import * as React from 'react';

import {
  Colors,
  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

export default function Button(props) {
  const size = useDimensions();

  return (
    <button
      type="button"
      className={props.isDisabled ? 'Disabled' : 'Hover'}
      onClick={props.onClick}
      style={{
        position: 'absolute',
        top: 120,
        left: props.align === 'left' ? -30 : 'inherit',
        right: props.align === 'right' ? size.width < Breakpoint.lg ? -14 : -30 : 'inherit',
        height: 60,
        width: 60,
        backgroundColor: props.isDisabled ? Colors.LIGHT_GREY : Colors.BLUE,
        border: `solid 2px ${props.isDisabled ? Colors.MEDIUM_WHITE : Colors.BLUE}`,
        borderRadius: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: `0px 3px 15px 2px ${Colors.DARK_GREY}40`,
        pointerEvents: 'auto',
      }}
    >
      {props.children}
    </button>
  );
}
