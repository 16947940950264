import * as React from 'react';

import {
  useDimensions,

  Breakpoint,
} from '@bluecurrent/web-component-lib';

import handleLocalUrlChange from '../../../functions/handleLocalUrlChange';

import Card from './Card';
import CardWrapper from './CardWrapper';

export default function Item(props) {
  const size = useDimensions();

  const useHighlighted = (e) => (
    e.text_block.title === undefined
    && e.icon === undefined
    && e.image === undefined
  );

  const layoutHandler = (row, item, index) => {
    if (
      row.length === 1
      && (index + 1) === row.length
      && item.icon === undefined
      && item.image !== undefined
      && size.width >= Breakpoint.sm
    ) {
      return 'horizontal';
    }
    return 'vertical';
  };

  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return props.item.link ? (
    <a
      href={handleLocalUrlChange(props.item.link.url)}
      target={props.item.link.target}
      style={{
        ...style,
        width: layoutHandler(props.row, props.item, props.i) === 'horizontal' ? '100%' : props.width,
      }}
    >
      <CardWrapper
        data={props.item}
        layout={layoutHandler(props.row, props.item, props.i)}
        highlight={useHighlighted(props.item)}
      >
        <Card
          data={props.item}
          length={props.length}
          params={props.params}
          highlight={useHighlighted(props.item)}
          layout={layoutHandler(props.row, props.item, props.i)}
        />
      </CardWrapper>
    </a>
  ) : (
    <div
      style={{
        ...style,
        width: layoutHandler(props.row, props.item, props.i) === 'horizontal' ? '100%' : props.width,
      }}
    >
      <CardWrapper
        data={props.item}
        layout={layoutHandler(props.row, props.item, props.i)}
        highlight={useHighlighted(props.item)}
      >
        <Card
          data={props.item}
          length={props.length}
          params={props.params}
          highlight={useHighlighted(props.item)}
          layout={layoutHandler(props.row, props.item, props.i)}
        />
      </CardWrapper>
    </div>
  );
}
