import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
  Title,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../../config/Setup.config';

import useSanityData from '../../../../hooks/useSanityData';

import getCardImage from '../../../../services/Sanity/query/getImage/getCardImage';

export default function Card(props) {
  const { i18n } = Lang.useTranslation();

  const image = useSanityData(
    getCardImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
      props.data.original_id,
    ),
  );

  const [isHover, setHover] = React.useState(false);

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const isClickable = props.data.link;
  const src = image[props.params.layoutIndex]?.layout?.Components?.cards?.array?.src;
  const isActive = (
    props.data.original_id === props.params.active
    || props.data.original_id === (props.params.active + 1)
    || props.data.original_id === (props.params.active + 2)
  );

  return (
    <div
      className="Hover"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        flex: 1,
        transform: `scale(${isActive ? 1 : 0.9})`,
        transition: 'transform ease .5s',
      }}
    >
      <div
        style={{
          minHeight: 300,
          flex: '0 1 300px',
          width: '100%',
          backgroundColor: Colors.MEDIUM_WHITE,
          backgroundImage: `url("${src}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: 10,
          boxShadow: (isClickable && isHover) ? `0px 3px 15px 2px ${Colors.DARK_GREY}40` : 'inherit',
          transition: isClickable ? 'box-shadow 0.2s linear' : 'inherit',
        }}
      />
      <div
        style={{
          height: '100%',
          width: 'calc(100% - 30px)',
          backgroundColor: Colors.LIGHT_GREY,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 15,
          paddingBottom: 20,
          borderRadius: 10,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          marginTop: -45,
          boxShadow: (isClickable && isHover) ? `0px 3px 15px 2px ${Colors.DARK_GREY}40` : 'inherit',
          transition: isClickable ? 'box-shadow 0.2s linear' : 'inherit',
        }}
      >
        <Title
          portableText={props.data.text_block.title !== undefined}
          heading={3}
        >
          {(props.data.text_block.title !== undefined) && props.data.text_block.title[lang]}
        </Title>
        <div
          style={{
            marginTop: 15,
            height: 'auto',
            transition: 'all ease .7s',
            overflow: 'hidden',
          }}
        >
          <Text
            lineHeight={1.3}
            color={isClickable ? Colors.DARK_GREY : Colors.GREY}
            portableText={props.data.text_block.text !== undefined}
          >
            {(props.data.text_block.text) && props.data.text_block.text[lang]}
          </Text>
        </div>
      </div>
    </div>
  );
}
