import * as React from 'react';

import {
  Colors,

  useDimensions,

  Icon,
} from '@bluecurrent/web-component-lib';

import handleLocalUrlChange from '../../../../functions/handleLocalUrlChange';

import handleResponsive from '../handleResponsive';

import Card from './Card';
import Button from './Button';

export default function Carousel(props) {
  const size = useDimensions();

  const ref = React.useRef(0);

  const [active, setActive] = React.useState(0);
  const [width, setWidth] = React.useState('100%');
  const [itemWidth, setItemWidth] = React.useState('100%');
  const [maxItems, setMaxItems] = React.useState(1);

  const getDimensions = () => {
    if (ref.current.clientWidth !== null) setWidth(ref.current.clientWidth);
  };

  React.useEffect(() => {
    getDimensions();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);

    return () => window.removeEventListener('resize', getDimensions);
  }, []);

  React.useEffect(() => {
    if (props.data !== undefined) {
      setItemWidth(width / handleResponsive(size.width).layout);
      setMaxItems(handleResponsive(size.width).layout);
    }
  }, [
    props.data,
    width,
    size,
  ]);

  const onNext = () => {
    if (active < (props.data.length - maxItems)) setActive(active + 1);
  };

  const onPrevious = () => {
    if (active >= 1) setActive(active - 1);
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        ref={ref}
        style={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: 15,
          transition: 'all ease .7s',
          transform: `
            translateX(
              calc(-${
                active < (props.data.length - maxItems)
                  ? active
                  : (props.data.length - maxItems)
              } * ((${(parseFloat(width + 15)) / maxItems}px))
            )
          `,
        }}
      >
        {
          props.data.map((item) => (
            <React.Fragment key={item.original_id}>
              {
                item.link ? (
                  <a
                    href={handleLocalUrlChange(item.link.url)}
                    style={{
                      minWidth: (itemWidth - 10),
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Card
                      data={item}
                      params={{
                        ...props.params,
                        active,
                      }}
                    />
                  </a>
                ) : (
                  <div
                    style={{
                      minWidth: (itemWidth - 10),
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Card
                      data={item}
                      params={{
                        ...props.params,
                        active,
                      }}
                    />
                  </div>
                )
              }
            </React.Fragment>
          ))
        }
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
        }}
      >
        <Button
          onClick={() => onPrevious()}
          align="left"
          isDisabled={active === 0}
        >
          <Icon
            name="ArrowLeft"
            height={20}
            width={20}
            color={active === 0 ? Colors.MEDIUM_GREY : Colors.WHITE}
          />
        </Button>
        <Button
          onClick={() => onNext()}
          align="right"
          isDisabled={active === (props.data.length - maxItems)}
        >
          <Icon
            name="ArrowRight"
            height={20}
            width={20}
            color={active === (props.data.length - maxItems) ? Colors.MEDIUM_GREY : Colors.WHITE}
          />
        </Button>
      </div>
    </div>
  );
}
