import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,

  useDimensions,

  Title,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import Carousel from './Carousel';
import Item from './Item';
import handleResponsive from './handleResponsive';

export default function Cards(props) {
  const length = props.data?.array.length;

  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const arrayWithOriginalId = props.data?.array.map((i, index) => ({
    original_id: index,
    ...i,
  }));

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!props.data.carousel) {
      const array = [];

      for (
        let i = 0;
        i < arrayWithOriginalId.length; i += handleResponsive(size.width).layout
      ) {
        array.push(
          arrayWithOriginalId.slice(
            i,
            i + handleResponsive(size.width).layout,
          ),
        );
      }

      setData(array);
    } else {
      setData(arrayWithOriginalId);
    }
  }, [
    size,
  ]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      {
        props.data.title && (
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <Title
              textAlign="left"
              heading={handleHeadingPrioritization(props.params.index)}
            >
              {props.data.title[lang]}
            </Title>
          </div>
        )
      }
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >

        {
          props.data.carousel ? (
            <Carousel
              data={arrayWithOriginalId}
              params={props.params}
            />
          ) : data.map((row, index) => (
            <div
              /* eslint-disable-next-line no-underscore-dangle */
              key={row._key}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: (
                  row.length < handleResponsive(size.width).layout
                ) ? 'flex-start'
                  : 'space-between',
                flexWrap: 'wrap',
                gap: 15,
                marginBottom: (data.length !== index + 1)
                  ? 40
                  : 0,
              }}
            >
              {
                row.map((item, i) => (
                  <React.Fragment
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={item._key}
                  >
                    <Item
                      i={i}
                      row={row}
                      item={item}
                      length={length}
                      params={props.params}
                      width={handleResponsive(size.width).width}
                    />
                  </React.Fragment>
                ))
              }
            </div>
          ))
        }
      </div>
    </motion.div>
  );
}
